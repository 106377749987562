import {
  AppstoreOutlined,
  ArrowDownOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import deleteIcon from "../../../images/delete-icon.png";
import { vaultImagesData } from "../../../redux/actions/opd/patienthistory";
import { patientInvoiceInfo } from "../../../redux/actions/opd/patientopdinvoice";
import {
  autoOpenRecVisit,
  certificateToggleAction,
  createFormArray,
  getOpdFormData,
  getPatientNotes,
  getPatientRisk,
  getSingleVisit,
  getSingleVisitStatus,
  openVisit,
  removeCertificateData,
  replicateVisitStatus,
  savePatientRisk,
  updateFormSpeechData,
  updateRiskFactors,
  viewVisitImage,
} from "../../../redux/actions/opd/patientreport";
import Button from "../../../UIComponents/Button/Button";
import InputField from "../../../UIComponents/Input/Input";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { toast } from "../../../UIComponents/Toast";
import "../UpdateRisks/UpdateRisks.css";
import Form from "./Form";
import SpeechtoText from "./SpeechtoText";
const riskFactors = [
  "ALCOHOL",
  "CHOLESTROL",
  "DIABETES MELLITUS",
  "DIET",
  "DM",
  "DYSLIPIDAEMIA",
  "FAMILY HISTORY",
  "HTN",
  "HYPERTENSION",
  "OBESITY",
  "PHYSICAL INACTIVITY",
  "SMOKING",
  "STRESS",
  "TOBACCO",
  "TRIGLYCERIDES",
  "LTBI-MANTOUX TEST",
  "LTBI-TB QUANTIFERON GOLD",
  "LTBI-CXR PA VIEW",
  "LTBI-H/O TB",
  "LTBI-H/O CONTACT WITH ACTIVE TB",
  "OTHERS",
];
const certificates = [
  {
    id: "Certificate of Medical Fitness",
    name: "Certificate of Medical Fitness",
  },
  {
    id: "Medical Certificate",
    name: "Medical Certificate",
  },
];

function UpdateRisks() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const previousVisits = useSelector(
    (state) => state.opdrecord.patientreport.previousVisits
  );
  console.log("dsfdsfgdsfdsf", previousVisits);
  const riskFactorsInfo = useSelector(
    (state) => state.opdrecord.patientreport.riskFactors
  );
  console.log("riskFactorsInfofromapi", riskFactorsInfo);
  const visitInfo = useSelector(
    (state) => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
  );
  const formArr = useSelector((state) => state.opdrecord.patientreport.formArr);
  const formInfo = useSelector((state) => state.opdrecord.patientreport.form);
  const active = useSelector((state) => state.opdrecord.patientreport.active);
  const singleAppointment = useSelector(
    (state) => state.opdrecord.patientHistory.singleAppointment
  );
  console.log("singleAppointment", singleAppointment);
  const autoRecId = useSelector(
    (state) => state.opdrecord.patientreport.autoRecPatientId
  );
  console.log("autoRecId", autoRecId);
  const getActivePatientId = useSelector(
    (state) => state.opdrecord.patientlist.selectedPatient
  );

  const openVisitsData = useSelector(
    (state) => state.opdrecord.patientreport.storeOpenVisit
  );
  console.log("openVisitsData", openVisitsData);

  const singleVisit = useSelector(
    (state) => state.opdrecord.patientreport.singleVisit
  );
  console.log("singleVisit", singleVisit);

  const myDomainInfo = useSelector((state) => state.login.myDomainInfo);

  const speechData = useSelector(
    (state) => state.opdrecord.patientreport.speechData
  );
  console.log("speechdatainupdaterisks", speechData);

  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleRisk, setVisibleRisk] = useState(false);
  const [prevForm, setPrevForm] = useState(false);
  const [form, setForm] = useState("");
  const [index, setIndex] = useState("");
  const [addRisk, setAddRisk] = useState([]);

  const modalFormClose = () => {
    setVisibleForm(false);
  };

  const showRiskModal = () => {
    setVisible(true);
  };
  const modalRiskClose = () => {
    setVisible(false);
    setAddRisk([]);
  };
  const showRiskModalPast = () => {
    setVisibleRisk(true);
  };

  const showFormModal = () => {
    setVisibleForm(true);
  };
  const showPrevForm = () => {
    setPrevForm(true);
  };
  const previousVisitsReverse = [...previousVisits]?.reverse();
  console.log("previousVisitsReverse", previousVisitsReverse);

  const getSingleVisits = (id) => {
    console.log("visitid", id);
    const singleVisit = previousVisitsReverse.find((item) => item._id === id);
    console.log("safdasfasfdsa", singleVisit);
    dispatch(replicateVisitStatus(singleVisit));
    dispatch(getSingleVisit(singleVisit));
    dispatch(getPatientNotes({ patientId: singleVisit.patientId }));
    dispatch(getPatientRisk({ patientId: singleVisit.patientId }));
    dispatch(getSingleVisitStatus(true));
    dispatch(vaultImagesData(singleVisit._id));
    dispatch(viewVisitImage(id));
  };

  useEffect(() => {
    if (previousVisitsReverse.length) {
      dispatch(getSingleVisit(previousVisitsReverse[0]));
      dispatch(autoOpenRecVisit(null));
    }
  }, [autoRecId]);

  useEffect(() => {
    if (singleAppointment?.appointment?._id && previousVisitsReverse.length) {
      dispatch(getSingleVisit(previousVisitsReverse[0]));
    }
  }, [singleAppointment?.appointment?._id, previousVisits]);

  const getActiveVisit = () => {
    dispatch(getSingleVisitStatus(false));
    dispatch(patientInvoiceInfo({ aptId: visitInfo.aptId, full: true }, "opd"));
  };

  const removeRiskAdd = (index) => {
    // if (riskFactorsInfo?.riskFactors?.length) {
    setAddRisk([...addRisk].filter((item, i) => i !== index));
    dispatch(
      updateRiskFactors({
        ...riskFactorsInfo,
        riskFactors: [...riskFactorsInfo.riskFactors].filter(
          (item, i) => i !== index
        ),
      })
    );
    // } else {
    // }
  };
  function add3Dots(string) {
    var dots = "...";
    var limit = 20;
    if (string ? string.length > limit : "") {
      string = string.substring(0, limit) + dots;
    }

    return string;
  }

  const onAddRiskNameChange = (e, i) => {
    if (riskFactorsInfo?.riskFactors?.length > 0) {
      let arr = [...riskFactorsInfo.riskFactors];
      arr[i].name = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      dispatch(updateRiskFactors({ ...riskFactorsInfo, riskFactors: arr }));
    } else {
      let arr = [...addRisk];
      arr[i].name = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      setAddRisk(arr);
    }
  };
  const onAddRiskValueChange = (e, i) => {
    if (riskFactorsInfo?.riskFactors?.length > 0) {
      let arr = [...riskFactorsInfo.riskFactors];
      arr[i].value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      dispatch(updateRiskFactors({ ...riskFactorsInfo, riskFactors: arr }));
    } else {
      let arr = [...addRisk];
      arr[i].value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      setAddRisk(arr);
    }
  };
  const onAddRiskDescChange = (e, i) => {
    if (riskFactorsInfo?.riskFactors?.length > 0) {
      let arr = [...riskFactorsInfo.riskFactors];
      arr[i].description = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      dispatch(updateRiskFactors({ ...riskFactorsInfo, riskFactors: arr }));
    } else {
      let arr = [...addRisk];
      arr[i].description = e.target.value.replace(/[^a-zA-Z\s]/g, "");
      setAddRisk(arr);
    }
  };

  const patientRisksHandler = () => {
    if (riskFactorsInfo?.riskFactors) {
      dispatch(
        savePatientRisk({
          doctorName: visitInfo?.doctorName,
          patientId: visitInfo?.patientId,
          riskFactors: riskFactorsInfo.riskFactors,
        })
      );
    }
    setVisible(false);
  };

  const openVisitHandler = () => {
    dispatch(openVisit({ aptId: visitInfo.aptId }));
  };

  const onCertificateChange = (value) => {
    setForm(value);
  };
  const downloadCertificate = () => {
    if (!form) {
      toast.error("Please select certificate");
    } else {
      dispatch(createFormArray([...formArr, { fileName: form }]));
      setForm("");
    }
  };
  const getCertificate = (form, i) => {
    setIndex(i);
    dispatch(getOpdFormData({ form }));
    if (form === "Medical Certificate") {
      dispatch(certificateToggleAction(true));
    } else {
      dispatch(certificateToggleAction(false));
    }
  };
  const deleteCertificates = () => {
    if (index === "") {
      console.log("deletecertificatecheckingif", index);
      dispatch(
        dispatch(
          createFormArray(
            [...formArr].filter((item, i) => i !== formArr.length - 1)
          )
        )
      );
    } else {
      console.log("deletecertificatecheckingelse", index);
      dispatch(createFormArray([...formArr].filter((item, i) => i !== index)));
      dispatch(removeCertificateData({}));
      // setIndex("")
    }
  };

  const appendRisk = (risk) => {
    if (risk === "OTHERS") {
      if (riskFactorsInfo?.riskFactors?.length) {
        dispatch(
          updateRiskFactors({
            ...riskFactorsInfo,
            riskFactors: [
              ...riskFactorsInfo.riskFactors,
              {
                name: null,
                value: null,
                description: null,
              },
            ],
          })
        );
      } else {
        // setAddRisk([...addRisk, {
        //     name: null,
        //     value: null,
        //     description: null
        // }])
        dispatch(
          updateRiskFactors({
            ...riskFactorsInfo,
            riskFactors: [
              ...addRisk,
              {
                name: null,
                value: null,
                description: null,
              },
            ],
          })
        );
      }
    } else {
      if (riskFactorsInfo?.riskFactors?.length) {
        dispatch(
          updateRiskFactors({
            ...riskFactorsInfo,
            riskFactors: [
              ...riskFactorsInfo.riskFactors,
              {
                name: risk,
                value: null,
                description: null,
              },
            ],
          })
        );
      } else {
        // setAddRisk([...addRisk, {
        //     name: risk,
        //     value: null,
        //     description: null
        // }])
        dispatch(
          updateRiskFactors({
            ...riskFactorsInfo,
            riskFactors: [
              ...addRisk,
              {
                name: risk,
                value: null,
                description: null,
              },
            ],
          })
        );
      }
    }
  };
  useEffect(() => {
    console.log("riskFactorsInfoaddrisk", addRisk);
  }, [addRisk]);

  return (
    <div>
      <div className="opd-record-update-risk">
        <div
          className="update-risks"
          style={{
            background: myDomainInfo?.DomainStyles?.buttonColor
              ? myDomainInfo?.DomainStyles?.buttonColor
              : "#00576E",
          }}
        >
          <h6>Update Risks</h6>
        </div>
        <div className="risk-form-btns">
          {active ? (
            <div>
              <Button className={"risk-btn"} onClick={showRiskModalPast}>
                Risk
              </Button>
              <div className="tri"></div>
            </div>
          ) : (
            <div>
              <Button className={"risk-btn"} onClick={showRiskModal}>
                Risk
              </Button>
              <div className="tri"></div>
            </div>
          )}
          {active ? (
            <Button className={"forms-btn"} onClick={showPrevForm}>
              Forms
            </Button>
          ) : (
            <Button className={"forms-btn"} onClick={showFormModal}>
              Forms
            </Button>
          )}
        </div>
        {singleAppointment?.appointment?.active === true ? (
          <div className="active-visit-date-btns">
            <Button onClick={getActiveVisit} className={"active-list-btn"}>
              ACTIVE VISIT
            </Button>
          </div>
        ) : (
          ""
        )}
        <div className="prev-visit-date-btns">
          {previousVisitsReverse &&
            previousVisitsReverse?.map((prev, i) => (
              <div className="active-visit-date-btns">
                {
                  singleAppointment?.appointment?.active === true ? (
                    <Button
                      className={"date-btn"}
                      onClick={() => getSingleVisits(prev._id, i)}
                    >
                      {moment(prev.visitEnd).format("DD/MM/YYYY")}
                    </Button>
                  ) : (
                    <Button
                      className={"date-btn"}
                      onClick={() => getSingleVisits(prev._id, i)}
                    >
                      {moment(prev.visitEnd).format("DD/MM/YYYY")}
                    </Button>
                  )
                  // <Button className={'date-btn'} onClick={() => getSingleVisits(prev._id, i)}>{moment(prev.visitEnd).format("DD/MM/YYYY")} {previousVisitsReverse[0]._id === prev._id ? <span onClick={openVisitHandler}><AppstoreOutlined /></span> : ''}</Button>
                }
              </div>
            ))}
        </div>
      </div>
      {visible && (
        <Modal
          title="Patient Risk Factor"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={modalRiskClose}
          width={1000}
        >
          <div>
            <div className="update-risk-modal-sec">
              <div className="risk-factors-left-sec">
                <div className="risk-factor-sec">
                  {riskFactors?.map((item) => (
                    <div className="risk-factor-name-sec">
                      <p onClick={() => appendRisk(item)}>{add3Dots(item)}</p>
                    </div>
                  ))}
                </div>
                {riskFactorsInfo?.riskFactors &&
                  riskFactorsInfo?.riskFactors?.map((item, i) => (
                    <div className="update-risk-add-sec">
                      <div>
                        <InputField
                          type="text"
                          placeholder="Name"
                          name={item.name}
                          value={item.name}
                          onChange={(e) => onAddRiskNameChange(e, i)}
                        />
                      </div>
                      <div>
                        <InputField
                          type="text"
                          placeholder="Value"
                          name={item.value}
                          value={item.value}
                          onChange={(e) => onAddRiskValueChange(e, i)}
                        />
                      </div>
                      <div>
                        <InputField
                          type="text"
                          placeholder="Description"
                          name={item.description}
                          value={item.description}
                          onChange={(e) => onAddRiskDescChange(e, i)}
                        />
                      </div>
                      <div>
                        <img
                          src={deleteIcon}
                          alt="delete"
                          className="medicine-table-delete"
                          onClick={() => removeRiskAdd(i)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="risk-update-history-sec">
                <p>Update History</p>
                {riskFactorsInfo?.riskFactorsLog?.map((item) => (
                  <>
                    <p>
                      {item.doctorName} &nbsp; {item.date}
                    </p>
                  </>
                ))}
              </div>
            </div>
            <div className="add-branch-modal-sec">
              <p></p>
              <div className="add-branch-modal-btn">
                <Button onClick={patientRisksHandler}>SAVE</Button>
                <Button
                  className={"add-branch-modal-cancel-btn"}
                  onClick={modalRiskClose}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {visibleForm && (
        <Modal
          title="Forms"
          centered
          visible={visibleForm}
          onOk={() => setVisibleForm(false)}
          onCancel={modalFormClose}
          width={1000}
        >
          <div>
            <SpeechtoText />
            <div className="opd-form-certificate-sec">
              <div className="opd-form-left-sec">
                <div className="opd-form-select-sec">
                  <SingleSelect
                    data={certificates}
                    placeholder="Select"
                    value={form}
                    onChange={onCertificateChange}
                  />
                </div>
                <div>
                  <ArrowDownOutlined
                    className="opd-form-download"
                    onClick={downloadCertificate}
                  />
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <PrinterOutlined className="opd-form-print" />
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
              <div onClick={() => deleteCertificates()}>
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="opd-form-delete"
                />
              </div>
            </div>
            <div className="opd-formname-sec">
              {formArr?.map((item, i) => (
                <p onClick={() => getCertificate(item.fileName, i)}>
                  {item.fileName}
                </p>
              ))}
            </div>
            {formInfo?.form ? (
              <div ref={componentRef}>
                <Form />
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      )}

      {prevForm && (
        <Modal
          title="Forms"
          centered
          visible={prevForm}
          onOk={() => setVisibleForm(false)}
          onCancel={() => setPrevForm(false)}
          width={1000}
        >
          <div>
            <SpeechtoText />
            <div className="opd-form-certificate-sec">
              <div className="opd-form-left-sec">
                <div className="opd-form-select-sec">
                  <SingleSelect
                    data={certificates}
                    placeholder="Select"
                    value={form}
                    onChange={onCertificateChange}
                  />
                </div>
                <div>
                  <ArrowDownOutlined className="opd-form-download" />
                </div>
                <div className="opd-form-delete-div">
                  <PrinterOutlined className="opd-form-print" />
                </div>
              </div>
              <div className="opd-form-delete-div">
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="opd-form-delete"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {visibleRisk && (
        <Modal
          title="Patient Risk Factor"
          centered
          visible={visibleRisk}
          onOk={() => setVisibleRisk(false)}
          onCancel={() => setVisibleRisk(false)}
          width={1000}
        >
          <div>
            <div className="update-risk-modal-sec">
              <div className="risk-factors-left-sec">
                {riskFactorsInfo?.riskFactors?.length > 0
                  ? riskFactorsInfo?.riskFactors?.map((item, i) => (
                      <div className="update-risk-add-sec">
                        <div>
                          <InputField
                            type="text"
                            placeholder="Name"
                            value={item.name}
                            disabled={true}
                          />
                        </div>
                        <div>
                          <InputField
                            type="text"
                            placeholder="Value"
                            value={item.value}
                            disabled={true}
                          />
                        </div>
                        <div>
                          <InputField
                            type="text"
                            placeholder="Description"
                            value={item.description}
                            disabled={true}
                          />
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
              <div className="risk-update-history-sec">
                <p>Update History</p>
                {riskFactorsInfo?.riskFactorsLog?.map((item) => (
                  <>
                    <p>
                      {item.doctorName} &nbsp; {item.date}
                    </p>
                  </>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default UpdateRisks;
