import React, { useState } from "react";
import NumberButton from "../../../UIComponents/NumberButton/NumberButton";
import MultiSelect from "../../../UIComponents/MultiSelect/MultiSelect";
import InputField from "../../../UIComponents/Input/Input";
import { DatePicker, Switch, Modal } from "antd";
import { useSelector } from "react-redux";
import Divider from "../../../UIComponents/Divider/Divider";
import "./ViewVisit.css";
import moment from "moment";
import TextAreaAnt from "../../../UIComponents/TextArea/TextArea";

const ViewVisit = () => {
  const singleVisit = useSelector(
    (state) => state.opdrecord.patientreport.singleVisit
  );
  console.log("single visit", singleVisit);
  const patientNote = useSelector(
    (state) => state.opdrecord.patientreport.patientNote
  );
  const images = useSelector((state) => state.opdrecord.patientreport.images);

  console.log("patient note", patientNote);
  const [singleImage, setSingleImage] = useState();
  const [imageView, setImageView] = useState(false);
  const viewSingleImage = (id) => {
    setImageView(true);
    setSingleImage(images.images.find((item) => item.key === id));
    console.log("singleImage", singleImage);
  };
  return (
    <div className="patient-reports">
      <h4>PATIENT REPORTS</h4>
      <div className="allergy-div">
        <NumberButton>1</NumberButton>
        <div className="allergy-intelorance">
          <h6>ALLERGY/INTELORANCE</h6>
        </div>
        <div className="view-right-width">
          <MultiSelect
            value={singleVisit?.allergens?.map((item) => item.name)}
            disabled={true}
            placeholder="Select Allergy"
            className={"allergy-multiselect"}
          />
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <div className="vitals-div">
        <NumberButton>2</NumberButton>
        <div className="vitals-3-div">
          <h6>VITALS</h6>
        </div>
        <div className="view-vital-section">
          <div className="view-vital-heading">
            <p>Vital</p>
            <p>value</p>
          </div>
          <div>
            <Divider />
          </div>

          {singleVisit?.vitals?.map((item, i) => (
            <div className="view-vital-heading">
              <p>{item?.name}</p>
              <p>{item?.value}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Divider />
      </div>
      <div>
        <div className="note-div">
          <NumberButton className="numberButton-4">3</NumberButton>
          <div className="note-4-div">
            <h6>NOTE</h6>
          </div>
          <div className="view-right-width">
            <InputField
              placeholder="Write Note"
              value={patientNote?.note}
              disabled={true}
              className={"note-input-field"}
            />
          </div>
        </div>
        <div>
          <Divider />
        </div>
      </div>
      <div>
        <div className="narratives-div">
          <NumberButton className="numberButton-5">4</NumberButton>
          <div className="narratives-5-div">
            <h6>NARRATIVES</h6>
          </div>
          <div className="view-right-width">
            <TextAreaAnt
              placeholder="Write text here..."
              disabled={true}
              value={singleVisit?.narratives}
            />
          </div>
        </div>
        <div>
          <Divider />
        </div>
      </div>
      <div>
        <div className="clinicalFindings-div">
          <NumberButton className="numberButton-6">5</NumberButton>
          <div className="clinicalFindings-6-div">
            <h6>CLINICAL FINDINGS</h6>
          </div>
          <div className="view-right-width">
            <MultiSelect
              value={singleVisit?.clinicalFinding?.map((item) => item.name)}
              disabled={true}
              placeholder="Select Clinical Findings"
              className={"clinical-findings-multiselect"}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className="radiologicalFindings-div">
          <NumberButton className="numberButton-7">6</NumberButton>
          <div className="radiologicalFindings-7-div">
            <h6>RADIOLOGICAL FINDINGS</h6>
          </div>
          <div className="view-right-width">
            <MultiSelect
              value={singleVisit?.radiologicalFinding?.map((item) => item.name)}
              disabled={true}
              placeholder="Select Radiological Findings"
              className={"radiological-findings-multiselect"}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className="diagnosis-div">
          <NumberButton className="numberButton-8">7</NumberButton>
          <div className="diagnosis-8-div">
            <h6>DIAGNOSIS</h6>
          </div>
          <div className="view-right-width">
            <MultiSelect
              value={singleVisit?.diagnosis?.map((item) => item.name)}
              disabled={true}
              placeholder="Select Diagnosis"
              className={"diagnosis-multiselect"}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div className="medicines-div">
        <NumberButton className="numberButton-9">8</NumberButton>
        <div className="medicines-9-div">
          <h6>MEDICINES</h6>
        </div>
        <div className="view-med-sec">
          <div className="view-medicine-headings">
            <p className="view-medicine-name">Name</p>
            <p className="view-medicine-days">Rx days</p>
            <p className="view-medicine-days">Dosage</p>
            <p className="view-medicine-days">Intake</p>
          </div>
          <div>
            <Divider />
          </div>
          {singleVisit?.medicines?.map((item, i) => (
            <div className="view-medicine-headings">
              <p className="view-medicine-name">{item.name}</p>
              <p className="view-medicine-days">{item.days}</p>
              <p className="view-medicine-days">
                {item.freq} - {item.timing}
              </p>
              <p className="view-medicine-days">{item.qtyArray}</p>
            </div>
          ))}
        </div>
      </div>
      <Divider />

      <div>
        <div className="tests-div">
          <NumberButton className="numberButton-10">9</NumberButton>
          <div className="tests-10-div">
            <h6>TESTS</h6>
          </div>
          <div className="view-right-width">
            <MultiSelect
              value={singleVisit?.lab?.map((item) => item.name)}
              disabled={true}
              placeholder="Select Diagnosis"
              className={"tests-multiselect"}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className="procedures-div">
          <NumberButton className="numberButton-11">10</NumberButton>
          <div className="procedures-11-div">
            <h6>PROCEDURES</h6>
          </div>
          <div className="view-right-width">
            <MultiSelect
              value={singleVisit?.procedure?.map((item) => item.name)}
              disabled={true}
              placeholder="Select Procedures"
              className={"procedures-multiselect"}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className="special-notes-div">
          <NumberButton className="numberButton-12">11</NumberButton>
          <div className="special-notes-12-div">
            <h6>SPECIAL NOTES</h6>
          </div>
          <div className="view-right-width">
            <TextAreaAnt
              placeholder="Write text here..."
              disabled={true}
              value={singleVisit?.specialNote}
            />
          </div>
        </div>
        <Divider />
      </div>
      {images?.images?.length ? (
        <div>
          <div className="file-div">
            <div className="upload-visit-sec">
              <div className="opd-file-img-button-sec">
                <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                  SCANNED FILES
                </h6>
              </div>
            </div>
            {images && images?.images?.length > 0 ? (
              <div className="opd-image-sec">
                {images?.images.map((item) => (
                  <div>
                    <img
                      style={{ width: "150px", height: "80px" }}
                      src={item.url}
                      alt="upload images"
                      onClick={() => viewSingleImage(item.key)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <Divider />
        </div>
      ) : (
        ""
      )}
      <div>
        <div className="follow-up-div">
          <NumberButton className="numberButton-14">12</NumberButton>
          <div className="follow-up-14-div">
            <h6>FOLLOWUP</h6>
            <p>
              SOS <Switch checked={singleVisit?.followupSOS} />
            </p>
          </div>
          <div>
            <DatePicker
              placeholder="Pick a day"
              value={
                singleVisit?.nextaptdate &&
                singleVisit?.nextaptdate !== "Invalid date"
                  ? moment(singleVisit.nextaptdate, "DD/MM/YYYY")
                  : ""
              }
              disabled={true}
            />
          </div>
        </div>
        <Divider />
      </div>
      <div>
        <div className="forward-div">
          <NumberButton className="numberButton-15">13</NumberButton>
          <div className="forward-15-div">
            <h6>FORWARD</h6>
          </div>
          <div className="forward-antSelect">
            <Switch checked={singleVisit?.markMedForFulfillment} />
          </div>
          <div className="opd-reference-sec">
            {singleVisit?.orderReference ? (
              <p>Ref: {singleVisit?.orderReference}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {imageView && (
        <Modal
          centered
          visible={imageView}
          onOk={() => setImageView(false)}
          onCancel={() => setImageView(false)}
          width={1000}
        >
          <div className="single-image-sec">
            <img style={{ width: "100%" }} src={singleImage.url} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ViewVisit;
