import React, { useEffect, useState, useCallback } from "react";
import NumberButton from "../../../../UIComponents/NumberButton/NumberButton";
import "./Medicines.css";
import Divider from "../../../../UIComponents/Divider/Divider";
import Button from "../../../../UIComponents/Button/Button";
import MultiSelect from "../../../../UIComponents/MultiSelect/MultiSelect";
import deleteIcon from "../../../../images/delete-icon.svg";
import editIcon from '../../../../images/Edit-pencil.svg';

import SingleSelect from "../../../../UIComponents/Select/SingleSelect";
import InputField from "../../../../UIComponents/Input/Input";
import { Checkbox, Modal, Radio, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import crossGrey from "../../../../images/cross-grey.png";
import {
	addMedicineOpd,
	getAllSuggestedMedicines,
	getSearchedMedicines,
	getSelectedMedicineRemarks,
	resetMedicineData,
	searchTermByMedicineGeneric,
} from "../../../../redux/actions/opd/patientreport";
import { patientInvoiceInfo, updateVisit } from "../../../../redux/actions/opd/patientopdinvoice";
import { toast } from "../../../../UIComponents/Toast";
import TextAreaAnt from "../../../../UIComponents/TextArea/TextArea";
import { SwapOutlined, CloseCircleOutlined } from "@ant-design/icons";
import useDoctorId from "../../../../utils/get-doctor-id";
import GlassPrescription from "../GlassPrescription/GlassPrescription";
const remarksTemplates = [
	{ key: "i", value: "1" },
	{ key: "ii", value: "2" },
	{ key: "iii", value: "3" },
	{ key: "qd", value: "every day" },
	{ key: "bid", value: "twice a day" },
	{ key: "tid", value: "three times a day" },
	{ key: "qid", value: "four times a day" },
	{ key: "qhs", value: "each night" },
	{ key: "qad", value: "every alternate day" },
	{ key: "q1h", value: "every hour" },
	{ key: "q2h", value: "every two hour" },
	{ key: "q3h", value: "every three hour" },
	{ key: "q4h", value: "every four hour" },
	{ key: "prn", value: "as needed" },
	{ key: "ac", value: "before meal" },
	{ key: "pc", value: "after meals or not on an empty stomach" },
	{ key: "po", value: "orally" },
	{ key: "pr", value: "rectally" },
	{ key: "sl", value: "sublingually" },
	{ key: "im", value: "intramuscularly" },
	{ key: "iv", value: "intravenously" },
	{ key: "sq", value: "subcutaneously" },
	{ key: "vg", value: "vaginally" },
	{ key: "am", value: "in the morning" },
	{ key: "pm", value: "in the evening" },
	{ key: "od", value: "right eye" },
	{ key: "os", value: "left eye" },
	{ key: "ou", value: "both eyes" },
	{ key: "aaa", value: "apply to affected area" },
];
const scList = [
	{
		id: "SC",
		name: "SC",
	},
	{
		id: "IV",
		name: "IV",
	},
	{
		id: "ID",
		name: "ID",
	},
	{
		id: "IM",
		name: "IM",
	},
	{
		id: "Deep IM",
		name: "Deep IM",
	},
	{
		id: "SC/IM",
		name: "SC/IM",
	},
];

const weeksData = [
	{
		id: "Mon",
		name: "Monday",
	},
	{
		id: "Tue",
		name: "Tuesday",
	},
	{
		id: "Wed",
		name: "Wednesday",
	},
	{
		id: "Thu",
		name: "Thursday",
	},
	{
		id: "Fri",
		name: "Friday",
	},
	{
		id: "Sat",
		name: "Saturday",
	},
	{
		id: "Sun",
		name: "Sunday",
	},
];

const daysList = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"30",
	"60",
	"90",
	"365",
];

const routeList = [
	{
		id: 'Right Eye',
		name: 'Right Eye',
	},
	{
		id: 'Left Eye',
		name: 'Left Eye',
	},
	{
		id: 'Both Eye',
		name: 'Both Eye',
	},
]

const dropsCourseTypes = [
	{ id: 'Daily', name: 'Daily' },
	{ id: 'Weekly', name: 'Weekly' }
]

const medicineType = [
	{ label: 'Tablet', value: 'Tablet' },
	{ label: 'ML', value: 'ML' },
	{ label: 'Drops', value: 'Drops' },
	{ label: 'Scoop', value: 'Scoop' },
]

const medTimInitalValle = {
	morning: true,
	afternoon: true,
	evening: false,
	night: true,
	altDay: false,
	sos: false,
	stat: false,
	weekly: false,
	forntNightly: false,
	monthly: false,
	timing: "Morning",
	qtyArray: "0,0,0",
	qty: 0,
	qtyBD: 0,
	qtyTDS: 0,
}

function Medicines() {
	const dispatch = useDispatch();
	const { TabPane } = Tabs;
	const searchMedList = useSelector(
		state => state.opdrecord.patientreport.searchMedList
	);
	const suggestedMedicines = useSelector(
		state => state.opdrecord.patientreport?.suggestedMedicines
	);
	const selectedMedicineRemarks = useSelector(
		state => state.opdrecord.patientreport.selectedMedicineRemarks
	);
	const medicine = useSelector(state => state.opdrecord.patientreport.medicine);
	const visitInfo = useSelector(
		state => state.opdrecord.patientopdinvoice.patientOpdInvoiceInfo
	);


	const searchTermMedList = useSelector(
		state => state.opdrecord.patientreport.searchMedicineByTerm
	);
	const doctorId = useDoctorId();

	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);
	// const [editMedicines, setEditMedicine] = useState(false);
	const [name, setName] = useState("");
	const [days, setDays] = useState(5);
	const [generic, setGeneric] = useState([]);
	const [medicines, setMedicines] = useState([]);
	const [diagnosis, setDiagnosis] = useState([]);
	const [showDropDown, setShowDropDown] = useState(false);
	const [dropsRoute, setDropsRoute] = useState(null)
	const [dropsDosage, setDropsDosage] = useState(null)
	const [dropsTiming, setDropsTiming] = useState(null)
	const [dropsCourseArr, setDropsCourseArr] = useState([])
	const [dropsCourse, setDropsCourse] = useState({ type: '', noOfDrops: '' })
	const [selectedMedicineType, setSelectedMedicineType] = useState('Tablet')
	const [freq, setFreq] = useState("OD");
	const [editfreq, setEditFreq] = useState("");
	const [editData,setEditData]=useState({})

	const [morning, setMorning] = useState(0);
	const [afternoon, setAfternoon] = useState(0);
	const [evening, setEvening] = useState(0);
	const [night, setNight] = useState(0);
	const [dlist, setDlist] = useState([]);
	const [remarks, setRemarks] = useState("");
	const [mou, setMou] = useState(null);
	const [mrp, setMrp] = useState(null);
	const [sku, setSku] = useState(null);
	const [warning, setWarning] = useState(null);
	const [place, setPlace] = useState("");
	const [searchTermVisible, setSearchTermVisible] = useState(false);
	const [searchType, setSearchType] = useState("0");
	const [medTime, setMedTime] = useState(medTimInitalValle);
	const [editingIndex, setEditingIndex] = useState(null);

	
	const medChecker = obj => {
		setMedTime(obj);
	};
	useEffect(() => {
		if (visitInfo.diagnosis) {
			setDiagnosis(visitInfo?.diagnosis?.map(item => item.name));
		}
	}, [visitInfo.diagnosis]);
	useEffect(() => {
		if (visitInfo.Medicines) {
			setMedicines(visitInfo?.Medicines?.map(item => item.name));
		}
	}, [visitInfo.medicines]);

	console.log("visitInfo",visitInfo)
		useEffect(()=>{
			if(visitInfo?.aptId){
			dispatch(patientInvoiceInfo({ aptId: visitInfo.aptId,full: true }))
			}
		},[visitInfo?.aptId])


	const addMedicine = () => {
		resetForm()
		setVisible(true);
		dispatch(
			getAllSuggestedMedicines({ diagnosis: diagnosis, medicines: medicines })
		);
		setMedicines([])
	};

	const onMedicineRadioChange = e => {
		const selectedValue = e?.target?.value;  // Ensure we always get a value
		console.log("Selected Frequency:", selectedValue);
		setFreq(selectedValue);
	};
	const onEditMedicineRadioChange = e => {
		const selectedValue = e?.target?.value;  // Ensure we always get a value
		console.log("Selected Frequency:", selectedValue);
		// setEditFreq(selectedValue);
		setFreq(selectedValue);

	};
	
	useEffect(() => {
		console.log("Editing item freq1:");
		switch (freq || editfreq) {
			case "OD":
				return medChecker({
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					altDay: false,
					timing: "Morning",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "BD":
				return medChecker({
					night: true,
					morning: true,
					afternoon: true,
					evening: false,
					altDay: false,
					timing: "Morning,Night",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "TDS":
				return medChecker({
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					altDay: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "QID":
				return medChecker({
					morning: true,
					afternoon: true,
					night: true,
					evening: true,
					altDay: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "Alt Day":
				return medChecker({
					altDay: true,
					weekly: false,
					forntNightly: false,
					monthly: false,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "Weekly":
				return medChecker({
					altDay: false,
					weekly: true,
					forntNightly: false,
					monthly: false,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "Fortnightly":
				return medChecker({
					altDay: false,
					weekly: false,
					forntNightly: true,
					monthly: false,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "Monthly":
				return medChecker({
					altDay: false,
					weekly: false,
					forntNightly: false,
					monthly: true,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "SOS":
				return medChecker({
					sos: true,
					stat: false,
					altDay: false,
					weekly: false,
					forntNightly: false,
					monthly: false,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "NA",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			case "STAT":
				return medChecker({
					sos: false,
					stat: true,
					altDay: false,
					weekly: false,
					forntNightly: false,
					monthly: false,
					morning: true,
					afternoon: true,
					night: true,
					evening: false,
					timing: "Morning",
					qtyArray: "0,0,0",
					qty: 0,
					qtyBD: 0,
					qtyTDS: 0,
				});
			default:
				return medChecker(medTime);
		}
	}, [freq,editfreq]);

	useEffect(() => {
		if (selectedMedicineRemarks.length) {
			setRemarks(selectedMedicineRemarks?.[0]);
		}
	}, [selectedMedicineRemarks]);

	const onWarningChange = e => {
		setWarning(e.target.value);
	};
	const onRemarksChange = e => {
		setRemarks(e.target.value);
	};
	const morningIncrement = () => {
		const morning = medTime.qty + 0.25
		setMedTime({
			...medTime,
			qty: morning,
		});
	};
	const morningDecrement = () => {
		if (medTime.qty === 0) return
		const morning = medTime.qty - 0.25
		setMedTime({
			...medTime,
			qty: morning,
		});
	};
	const afternoonIncrement = () => {
		const afternoon = medTime.qtyBD + 0.25
		setMedTime({
			...medTime,
			qtyBD: afternoon,
		});
	};
	const afternoonDecrement = () => {
		if (medTime.qtyBD === 0) return
		const morning = medTime.qtyBD - 0.25
		setMedTime({
			...medTime,
			qtyBD: morning,
		});
	};
	const eveningIncrement = () => {
		const evening = medTime.qtyBD - 0.25
		setMedTime({
			...medTime,
			qtyBD: evening,
		});
	};
	const eveningDecrement = () => {
		if (medTime.qtyBD === 0) return
		const evening = medTime.qtyBD - 0.25
		setMedTime({
			...medTime,
			qtyBD: evening,
		});
	};
	const nightIncrement = () => {
		const night = medTime.qtyTDS + 0.25
		setMedTime({
			...medTime,
			qtyTDS: night,
		});
	};
	const nightDecrement = () => {
		if (medTime.qtyTDS === 0) return
		const night = medTime.qtyTDS - 0.25
		setMedTime({
			...medTime,
			qtyTDS: night,
		});
	};
	const onDaysChange = value => {
		setDays(value);
	};
	const onPlaceChange = value => {
		setPlace(value);
	};
	const selectedSuggestedMedicine = name => {
		const gen = suggestedMedicines?.find(item => item.name === name);
		console.log("sku find", gen);
		setName(name);
		if (gen?.freq) {
			setFreq(gen.freq);
			setEditFreq(gen.freq);
		}
		if (gen?.remarks?.length) setRemarks(gen?.remarks?.[0]);
		setGeneric(gen?.generic);
		setMrp(gen?.mrp);
		setSku(gen?.sku);
		setMou(gen?.mou);
	};
	console.log("name==>", name,mou,mrp,sku)
	const addMedicineSubmit = () => {
		if (!(name && mou && mrp && sku)) {
			return toast.error("Please select a valid medicine");
		}
		const { qty, qtyBD, qtyTDS } = setQtyBasedOnFreq()
		if (!dropsRoute) {
			if (freq === 'OD' && (qty ? 1 : 0) + (qtyBD ? 1 : 0) + (qtyTDS ? 1 : 0) < 1) return toast.error("Please select a valid qty");
			if (freq === 'BD' && (qty ? 1 : 0) + (qtyBD ? 1 : 0) + (qtyTDS ? 1 : 0) < 2) return toast.error("Please select a valid qty");
			if (freq === 'TDS' && !(qty && qtyBD && qtyTDS)) return toast.error("Please select a valid qty");
			if (!(qty || qtyBD || qtyTDS)) return toast.error("Please select a valid qty");
		}
		if (
			visitInfo.hasOwnProperty("medicines") &&
			visitInfo?.medicines?.length
		) {
			const { qty, qtyBD, qtyTDS } = setQtyBasedOnFreq()
			dispatch(
				updateVisit({
					...visitInfo,
					medicines: [
						...visitInfo.medicines,
						{
							name: name,
							days: days,
							freq: freq,
							qty,
							qtyBD,
							qtyTDS,
							dropsRoute,
							dropsDosage,
							dropsTiming,
							dropsCourse: dropsCourseArr,
							medicineType: selectedMedicineType,
							qtyArray: `${qty},${qtyBD},${qtyTDS}`,
							timing: medTime.timing,
							generic: generic,
							dlist: dlist,
							remarks: remarks,
							warning: warning,
							place: place,
							mou: mou,
							sku: sku,
							mrp: mrp,
						},
					],
				})
			);
			// resetForm();
			dispatch(
				getAllSuggestedMedicines({
					medicines: medicines,
					diagnosis: diagnosis,
				})
			);
			setName("");
			setDays(5);
			setWarning(null);
			setGeneric([]);
			setRemarks("");
			setDropsDosage(null)
			setDropsRoute(null)
			setDropsTiming(null)
			setDropsCourseArr([])
			setFreq("OD");
			setEditFreq("");
			setPlace("");
			medChecker(medTimInitalValle);
			setMorning(0);
			setAfternoon(0)
			setNight(0)
			setMrp(null);
			setSku(null);
			setMou(null);
			setSelectedMedicineType('Tablet')
			setVisible(false)
		} else {
			const { qty, qtyBD, qtyTDS } = setQtyBasedOnFreq()
			dispatch(
				updateVisit({
					...visitInfo,
					medicines: [
						...medicine,
						{ 
							name: name,
							days: days,
							freq: freq,
							qty,
							qtyBD,
							qtyTDS,
							dropsRoute,
							dropsDosage,
							dropsTiming,
							dropsCourse: dropsCourseArr,
							medicineType: selectedMedicineType,
							qtyArray: `${qty},${qtyBD},${qtyTDS}`,
							timing: medTime.timing,
							generic: generic,
							dlist: dlist,
							remarks: remarks,
							warning: warning,
							place: place,
							mou: mou,
							sku: sku,
							mrp: mrp,
						},
					],
				})
			);
			dispatch(
				getAllSuggestedMedicines({
					medicines: medicines,
					diagnosis: diagnosis,
				})
			);
			setName("");
			setDays(5);
			setWarning(null);
			setGeneric([]);
			setRemarks("");
			setDropsDosage(null)
			setDropsRoute(null)
			setDropsTiming(null)
			setDropsCourseArr([])
			setFreq("OD");
			setEditFreq("");
			setPlace("");
			medChecker(medTimInitalValle);
			setMorning(0);
			setAfternoon(0)
			setNight(0)
			setMrp(null);
			setSku(null);
			setMou(null);
			setSelectedMedicineType('Tablet')
		    setVisible(false);

		}
	};
	const addEditMedicineSubmit = () => {
		if (!(name && mou && mrp && sku)) {
			return toast.error("Please select a valid medicine");
		}
	
		const { qty, qtyBD, qtyTDS } = setQtyBasedOnFreq();
	
		if (!dropsRoute) {
			if (editfreq === 'OD' && (qty ? 1 : 0) + (qtyBD ? 1 : 0) + (qtyTDS ? 1 : 0) < 1) 
				return toast.error("Please select a valid qty");
			if (editfreq === 'BD' && (qty ? 1 : 0) + (qtyBD ? 1 : 0) + (qtyTDS ? 1 : 0) < 2) 
				return toast.error("Please select a valid qty");
			if (editfreq === 'TDS' && !(qty && qtyBD && qtyTDS)) 
				return toast.error("Please select a valid qty");
			if (!(qty || qtyBD || qtyTDS)) 
				return toast.error("Please select a valid qty");
		}
	
		const newMedicine = {
			name,
			days,
			freq,
			qty,
			qtyBD,
			qtyTDS,
			dropsRoute,
			dropsDosage,
			dropsTiming,
			dropsCourse: dropsCourseArr,
			medicineType: selectedMedicineType,
			qtyArray: `${qty},${qtyBD},${qtyTDS}`,
			timing: medTime.timing,
			generic,
			dlist,
			remarks,
			warning,
			place,
			mou,
			sku,
			mrp,
		};
	
		// Check if we are editing an existing medicine
		let updatedMedicines;
		if (editingIndex !== null) {
			// Update the specific index with the new medicine
			updatedMedicines = visitInfo.medicines.map((med, i) =>
				i === editingIndex ? newMedicine : med
			);
			setVisible1(false)
		} else {
			// Add new medicine to the list
			updatedMedicines = [...visitInfo.medicines, newMedicine];
		}
	
		// Dispatch the updated medicines list
		dispatch(updateVisit({
			...visitInfo,
			medicines: updatedMedicines
		}));
	
		dispatch(getAllSuggestedMedicines({
			medicines,
			diagnosis,
		}));
	
		// Reset form and editing index
		// resetForm();
		setEditingIndex(null);  // Reset editing index after submission
	};


// Function to reset form fields
const resetForm = () => {
    setName("");
    setDays(5);
    setWarning(null);
    setGeneric([]);
    setRemarks("");
    setDropsDosage(null);
    setDropsRoute(null);
    setDropsTiming(null);
    setDropsCourseArr([]);
    setFreq("OD");
    setEditFreq("");
	setRemarks("")
	// setMedTime({...medTime})
	setMedTime({})
    medChecker(medTimInitalValle);
    setMorning(0);
    setAfternoon(0);
    setNight(0);
    setMrp(null);
    setSku(null);
    setMou(null);
    setSelectedMedicineType("Tablet");
};

	const deleteMedicine = index => {
		dispatch(addMedicineOpd([...medicine].filter((item, i) => i !== index)));
		dispatch(
			updateVisit({
				...visitInfo,
				medicines: [...visitInfo.medicines].filter((item, i) => i !== index),
			})
		);
	};

	

//edit
useEffect(()=>{
	console.log("medicinetimgings",medTime,freq, editfreq)
},[medTime])

console.log("outside useeffect",editingIndex)

useEffect(() => {
	if (editingIndex !== null && editingIndex !== undefined && editData) {
	  console.log("outside inside", editingIndex);
  
	  let timingArr = editData?.timing?.split(',').filter(Boolean) || [];
  
	  setMedTime((prevMedTime) => ({
		...prevMedTime,
		qty: editData?.qty || "",
		qtyBD: editData?.qtyBD || "",
		qtyTDS: editData?.qtyTDS || "",
		timing: timingArr.join(','),
	  }));
	}
  }, [editData, editingIndex]);
  
// useEffect(()=>{
// 	if(editingIndex){
// 		console.log("editingIndex",editingIndex)
// 		let timingArr = editData.timing.split(',').filter(arr => arr)
// 		setMedTime({
// 			...medTime,
// 			qty: editData?.qty,
// 		    qtyBD: editData?.qtyBD,
// 			qtyTDS: editData?.qtyTDS,
// 			timing: timingArr.join(',')
			
// 		});
// 	}
// },[editData,editingIndex])


	const editMedicine =(item,index)=>{
		console.log("items===>", item)
		setEditData(item)
		setVisible1(true);
		setEditingIndex(index)
		// setEditMedicine(true)
		setName(item?.name) 
		setMrp(item?.mrp)
		setMou(item?.mou)
		setSku(item?.sku);
		setDays(item?.days)
		setWarning(item?.generic)
		setRemarks(item?.remarks)
		setFreq(item?.freq);
		// setEditFreq(item?.freq);	
		setMedTime({
			...medTime,
			qty: item?.qty,
		    qtyBD: item?.qtyBD,
			qtyTDS: item?.qtyTDS,			
		});
	}




	

	// Open the modal AFTER the state has updated
useEffect(() => {
    if (editingIndex !== null) {
        // setEditMedicine(true);
        setVisible1(true);
    }
}, [editingIndex]); // Runs when editingIndex updates
	const onSearchRadioChange = e => {
		setSearchType(e.target.value);
	};
	const addMedicineClose = () => {
		// resetForm()
		setVisible(false);
		// setEditMedicine(false)
	};
	const addEditMedicineClose = () => {
		setVisible1(false);
		// resetForm()
		// setEditMedicine(false)
	};
	const onMedWeeksChange = e => {
		console.log("day selected", e.target.value);
		if (dlist.includes(e.target.value)) {
			const data = dlist.filter(arr => arr !== e.target.value)
			setDlist(data)
		} else {
			setDlist([...dlist, e.target.value]);
		}
	};
	const appyRemarkTemplete = key => {
		const templete = remarksTemplates.find(item => item.key === key);
		setRemarks(prev => {
			return `${prev} ${templete.value}`;
		});
	};
	const removeRemarks = () => {
		setRemarks("");
	};
	const getSearchTermMedicine = () => {
		dispatch(searchTermByMedicineGeneric(generic));
		setSearchTermVisible(true);
	};
	const handleEmptySpaceClick = () => {
		setSearchTermVisible(false);
	};
	const onCloseSearchList = () => {
		setSearchTermVisible(false);
	};
	const applySearchTermMed = name => {
		const gen = searchTermMedList?.find(item => item.name === name);
		console.log("sku find", gen);
		setName(name);
		if (gen?.freq) {
			setFreq(gen.freq);
			setEditFreq(gen.freq);
		}
		if (gen?.remarks?.length) setRemarks(gen?.remarks?.[0]);
		setGeneric(gen?.generic);
		setMrp(gen?.mrp);
		setSku(gen?.sku);
		setMou(gen?.mou);
		setSearchTermVisible(false);
	};
	const getData = e => {
		console.log("getdata", e.target.value, searchType);
		if (e.target.value.length > 3) {
			setShowDropDown(true);
			dispatch(getSearchedMedicines(e.target.value, searchType));
		}
	};
	const onMedicineNameChange = e => {
		setName(e.target.value);
		betterFunc(e);
	};
	const deBounceFunc = (fn, interval) => {
		let timer;
		return function (e) {
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				fn(e);
			}, interval);
		};
	};
	const betterFunc = useCallback(deBounceFunc(getData, 300), [searchType]);
	const handleOptionSelected = i => {
		console.log("djkdd");
		searchMedList?.map((e, index) => {
			if (index === i) {
				setName(e.name);
				const gen = searchMedList?.find(item => item.name === e.name);
				console.log("sku find", gen);
				if (gen.freq) {
					setFreq(gen?.freq);
					setEditFreq(gen?.freq);
				}
				setGeneric(gen?.generic);
				setMrp(gen?.mrp);
				setSku(gen?.sku);
				setMou(gen?.mou);
				dispatch(getSelectedMedicineRemarks({ doctorId, name: e.name }));
				setMedicines([...medicines, e.name]);
				dispatch(resetMedicineData([]));
				setShowDropDown(false);
			}
		});
	};
	const removeMedineName = () => {
		setName("");
		dispatch(resetMedicineData([]));
	};
	const handleFocusOut = e => {
		setShowDropDown(false);
	};
	const handleFocus = () => {
		setShowDropDown(true);
	};
	const addDropsCourseList = () => {
		console.log('Inside addWeeklyCourse', !(dropsCourse.type && dropsCourse.noOfDrops))
		if (!(dropsCourse.type && dropsCourse.noOfDrops)) return;
		else {
			const course = [...dropsCourseArr]
			course.push(dropsCourse)
			setDropsCourseArr(course)
			setDropsCourse({})
		}
	}
	const deleteDropsCourseList = (arrIndex) => {

		console.log("🚀 ~ deleteDropsCourseList ~ week:", arrIndex)
		if (!arrIndex) return
		const filteredWeeklyCourse = dropsCourseArr.filter((arr, index) => index !== arrIndex)
		setDropsCourseArr(filteredWeeklyCourse)
	}
	const onChangeOfTimings = (value) => {

		console.log("value===>",value)

		let timingArr = medTime.timing.split(',').filter(arr => arr)

		if (timingArr.includes(value)) {
			timingArr = timingArr.filter(arr => arr !== value).join(',')

			return setMedTime({
				...medTime,
				timing: timingArr,
				qty: value === "Morning" ? 0 : medTime.qty,
				qtyBD: value === "Afternoon" ? 0 : medTime.qtyBD,
				qtyTDS: value === "Night" ? 0 : medTime.qtyTDS
			})
		}

		if (freq === 'OD' && timingArr.length === 0) {
			timingArr.push(value)
			return setMedTime({
				...medTime,
				timing: timingArr.join(',')
			})
		}
		if (freq === 'BD' && timingArr.length < 2) {
			timingArr.push(value)
			return setMedTime({
				...medTime,
				timing: timingArr.join(',')
			})
		}

	}
	const setQtyBasedOnFreq = () => {
		const moveOnesToStart = (arr) => {
			// Filter out all 1s and 0s
			let ones = arr.filter(x => x);
			let zeros = arr.filter(x => x === 0);
			// Concatenate 1s and 0s
			return ones.concat(zeros);
		}
		if (dropsRoute) return { qty: 1, qtyBD: 0, qtyTDS: 0 }
		// if (freq === "OD") {
		// 	const [qty, qtyBD, qtyTDS] = moveOnesToStart([medTime.qty, medTime.qtyBD, medTime.qtyTDS])
		// 	return { qty, qtyBD, qtyTDS }
		// }

		// if (freq === "BD") {
		// 	const [qty, qtyBD, qtyTDS] = moveOnesToStart([medTime.qty, medTime.qtyBD, medTime.qtyTDS])
		// 	return { qty, qtyBD, qtyTDS }
		// }

		return { qty: medTime.qty, qtyBD: medTime.qtyBD, qtyTDS: medTime.qtyTDS }

		// return {}
	}

	console.log("visitInfo",visitInfo,visitInfo?.medicines)

	return (
		<div>
			<div className="medicines-div">
				<NumberButton className="numberButton-9">9</NumberButton>
				<div className="medicines-9-div">
					<h6>MEDICINES</h6>
					<p onClick={addMedicine}>Add New Medicines</p>
				</div>
				<div className="selected-med-sec">
					<div className="medicine-headings">
						<table>
							<tbody>
								<tr>
									<td style={{ padding: '0 5px' }} width={'40%'}><b>Name</b></td>
									<td style={{ padding: '0 5px' }} width={'10%'}><b>Rx days</b></td>
									<td style={{ padding: '0 5px' }} width={'15%'}><b>Route</b></td>
									<td style={{ padding: '0 5px' }} width={'20%'}><b>Dosage</b></td>
									<td style={{ padding: '0 5px' }} width={'20%'}><b>Intake</b></td>
									<td style={{ padding: '0 5px' }} width={'10%'}><b>Action</b></td>
								</tr>
								{visitInfo?.medicines?.length ? (
									visitInfo?.medicines?.map((item, i) => {
										const isMedicineTablet = !item.dropsRoute
										return (
											(
												<tr>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>{item?.name}</td>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>{item?.days}</td>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>
														{item.dropsRoute ?
															`${item.dropsRoute} ${item.dropsDosage} Drop(s) ${item.dropsTiming} time(s) a day`
															: null
														}
													</td>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>
														{isMedicineTablet ? (
															<>
																{item?.freq} - {item?.timing}
															</>
														) : (
															<>
																{item.dropsCourse.length ? (
																	<div>
																		<div>Weekly Course</div>
																		{item.dropsCourse.map((course, index) => (
																			<div style={{ fontSize: 10 }}>
																				{`${course.noOfDrops} Drop(s) in a day, for ${course.type} ${index + 1}`}
																			</div>
																		))}
																	</div>
																) : null}
															</>
														)}
													</td>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>{item.dropsRoute ? `${item.dropsDosage || '-'} Drop(s)` : item?.qtyArray}</td>
													<td style={{ padding: '0 5px', height: '30px', verticalAlign: 'super' }}>
														<div className="delete-icon-div" style={{display:"flex"}}>
															<img
																src={deleteIcon}
																onClick={() => deleteMedicine(i)}
																className="medicine-table-delete"
																alt="deleteIcon"
															/>
															<img
																src={editIcon}
																onClick={() => editMedicine(item,i)}
																className="medicine-table-edit"
																alt="deleteIcon"
															/>
														
														</div>
													</td>
												</tr>
											)
										)
									})
								) : null}
							</tbody>
						</table>
					</div>
					{/* {visitInfo?.medicines?.length
						? visitInfo?.medicines?.map((item, i) => (
							<div className="medicine-names-days">
								<p>{item?.name}</p>
								<p>{item?.days}</p>
								<p>{item.dropsRoute || ''}</p>
								<p>
									{item?.freq} - {item?.timing}
								</p>
								<p>{item?.qtyArray}</p>
								<div className="delete-icon-div">
									<img
										src={deleteIcon}
										onClick={() => deleteMedicine(i)}
										className="medicine-table-delete"
										alt="deleteIcon"
									/>
								</div>
							</div>
						))
						: ""} */}
				</div>
			</div>
			{visitInfo.glassPrescriptions && visitInfo.glassPrescriptions.length ? (
				<div className="medicines-div">
					<NumberButton className="numberButton-9">9.1</NumberButton>
					<div className="medicines-9-div">
						<h6>Glass Prescription</h6>
					</div>

					<div className="selected-med-sec">
						<table class="no-border">
							<tr>
								<td>
									<b>L-SPH</b>
								</td>
								<td>
									<b>L-CYL.</b>
								</td>
								<td>
									<b>L-AXIS</b>
								</td>
								<td>
									<b>L-V.A.</b>
								</td>
								<td>
									<b>R-SPH</b>
								</td>
								<td>
									<b>R-CYL.</b>
								</td>
								<td>
									<b>R-AXIS</b>
								</td>
								<td>
									<b>R-V.A.</b>
								</td>
								<td>
									<b>Action</b>
								</td>
							</tr>
							{visitInfo.glassPrescriptions.map(arr => (
								<tr className="glass-prescription-values">
									<td>{arr.lsph || "-"}</td>
									<td>{arr.lcyl || "-"}</td>
									<td>{arr.laxis || "-"}</td>
									<td>{arr.lva || "-"}</td>
									<td>{arr.rsph || "-"}</td>
									<td>{arr.rcyl || "-"}</td>
									<td>{arr.raxis || "-"}</td>
									<td>{arr.rva || "-"}</td>
									<td>
										<div className="delete-icon-div">
											<img
												src={deleteIcon}
												onClick={() => dispatch(updateVisit({
													...visitInfo,
													glassPrescriptions: [],
													lensAdvised: []
												}))}
												className="medicine-table-delete"
												alt="deleteIcon"
											/>
										</div>
									</td>
								</tr>
							))}
						</table>
					</div>
				</div>
			) : null}
			{visitInfo.lensAdvised && visitInfo.lensAdvised.length ? (
				<div className="medicines-div">
					<NumberButton className="numberButton-9">9.2</NumberButton>
					<div className="medicines-9-div">
						<h6>Glass Lens Advised</h6>
					</div>

					<div className="selected-med-sec lens-advised">
						{visitInfo.lensAdvised.map(arr => (
							<div>
								{arr}
							</div>
						))}
					</div>
				</div>
			) : null}
			<Divider />

			{visible1 && (
				<Modal
					centered
					visible={visible1}
					onOk={addMedicineSubmit}
					onCancel={addEditMedicineClose}
					width={1100}
				>
					<Tabs
						defaultActiveKey={1}
						className="add-medicine-main-tab"
						onChange={() => { }}
						centered
					>
						<TabPane
							key="1"
							tab={
								<span className="add-medicine-tabs">
									<p className="add-medicine-tab-title" onClick={() => { }}>
										Medicine
									</p>
								</span>
							}
						>
							<div>
								<h3 className="opd-addmedicine-title">ADD MEDICINE</h3>

								<div className="addmedicine-main-section">
									
													
									<div className="addmedicine-right-section">
										<p className="select-med-title">Selected Medicine</p>
										<div className="med-name-select-sec">
											<Radio.Group
												onChange={onSearchRadioChange}
												value={searchType}
											>
												<Radio value="0">NAME</Radio>
												<Radio value="1">SALT</Radio>
											</Radio.Group>

											<div className="medicine-search-input">
												<InputField
													placeholder="Write Note"
													value={name}
													disabled={true}
												/>
											</div>
											<div className="add-med-place-sec">
												<SingleSelect
													data={scList}
													placeholder="Select "
													value={place}
													onChange={onPlaceChange}
												/>
											</div>
										</div>
										<div className="opd-drops-input">
											<div className="opd-drops-route">
												<SingleSelect
													data={routeList}
													placeholder="Route"
													value={dropsRoute}
													onChange={(e) => setDropsRoute(e)}
												/>
											</div>
											<div className="opd-drops-dosage">
												<InputField
													placeholder="Dosage"
													value={dropsDosage}
													onChange={(e) => setDropsDosage(e.target.value)}
												/>
											</div>
											<div className="opd-drops-dosage">
												<InputField
													placeholder="Timing"
													value={dropsTiming}
													onChange={(e) => setDropsTiming(e.target.value)}
												/>
											</div>
										</div>
										<div className="od-bd-timings-section">
											<Radio.Group
												className="med-radio-active"
												onChange={onEditMedicineRadioChange}
												value={freq}
											>
												<Radio value="OD">OD</Radio>
												<Radio value="BD">BD</Radio>
												<Radio value="TDS">TDS</Radio>
												<Radio value="QID">QID</Radio>
												<Radio value="SOS">SOS</Radio>
												<Radio value="STAT">STAT</Radio>
												<Radio value="Alt Day">Alt Day</Radio>
												<Radio value="Weekly">Weekly</Radio>
												<Radio value="Fortnightly">Fortnightly</Radio>
												<Radio value="Monthly">Monthly</Radio>
											</Radio.Group>
										</div>
										<div className="med-day-inc-dec-section">
											<div className="add-med-weeks">
												{medTime.altDay ||
													medTime.weekly ||
													medTime.forntNightly ||
													medTime.monthly
													? weeksData.map(item => (
														<Checkbox
															value={item.id}
															onChange={onMedWeeksChange}
														>
															{item.name}
														</Checkbox>
													))
													: ""}
											</div>
											{medTime.morning || medTime.sos || medTime.stat ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														{console.log('Inside render', medTime)}
														<Checkbox
															checked={medTime.timing.split(',').includes('Morning') || medTime.timing === 'NA'}
															value="Morning"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															MORNING
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Morning') || medTime.timing === 'NA')}
															value={medTime.qty}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qty: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.afternoon ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Afternoon') || medTime.timing === 'NA'}
															value="Afternoon"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															AFTERNOON
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Afternoon') || medTime.timing === 'NA')}
															value={medTime.qtyBD}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyBD: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.evening ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Evening') || medTime.timing === 'NA'}
															value="Evening"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															Evening
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Evening') || medTime.timing === 'NA')}
															value={medTime.qtyBD}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyBD: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.night ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Night') || medTime.timing === 'NA'}
															value="Night"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															NIGHT
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Night') || medTime.timing === 'NA')}
															value={medTime.qtyTDS}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyTDS: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											<div className="med-dose-timing-sec">
												<p>
													<Checkbox checked={true}>DAYS</Checkbox>
												</p>
												<div className="med-days-sec">
													<SingleSelect
														data={daysList}
														value={days}
														onChange={onDaysChange}
													/>
												</div>
											</div>
											<div className="med-dose-timing-sec">
												<p className="route-continaer">
													<SingleSelect
														data={dropsCourseTypes}
														placeholder="Drops Duration"
														value={dropsCourse.type}
														onChange={(e) => setDropsCourse({
															...dropsCourse,
															type: e
														})}
													/>
												</p>
												<div className="med-weekly-course">
													<InputField
														placeholder="No of Drops"
														value={dropsCourse.noOfDrops}
														onChange={(e) => setDropsCourse({
															...dropsCourse,
															noOfDrops: e.target.value
														})}
													/>
													<Button onClick={addDropsCourseList}>ADD</Button>
												</div>
											</div>
											<div className="med-dose-timing-sec">
												<p></p>
												<div className="weekly-course-array">
													{dropsCourseArr.map((arr, index) => (
														<div className="weekly-course-array-div">
															<div>
																{`${arr.noOfDrops} Drop(s) in a day, for ${arr.type} ${index + 1}`}
															</div>
															<div>
																<div className="delete-icon-div">
																	<img
																		src={deleteIcon}
																		onClick={() => deleteDropsCourseList(index)}
																		className="medicine-table-delete"
																		alt="deleteIcon"
																	/>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>

										<div className="med-dose-desc-sec">
											<TextAreaAnt
												className={"med-note-ht"}
												placeholder="Write text here..."
												onChange={onRemarksChange}
												value={remarks}
											/>
										</div>
										<div className="warning-sec">
											<InputField
												placeholder="Warning"
												value={warning}
												onChange={onWarningChange}
											/>
										</div>
										<div className="warning-sec">
											<MultiSelect
												data={scList}
												value={
													Array.isArray(generic) && generic?.map(item => item)
												}
												dropdownClassName={"select_dropdown"}
											/>
										</div>
										<div className="opd-remarkstemplete-sec">
											{remarksTemplates?.map(item => (
												<p onClick={() => appyRemarkTemplete(item.key)}>
													{item.key}
												</p>
											))}
											<span
												className="opd-remarkstemplete-clear"
												onClick={removeRemarks}
											>
												Clear
											</span>
										</div>


										<div className="add-branch-modal-sec">
											{/* <p></p> */}
											<div className="add-branch-modal-btn">
											
												<Button onClick={addEditMedicineSubmit}>ADD</Button>
										
												<Button
													className={"add-branch-modal-cancel-btn"}
													onClick={addEditMedicineClose}
												>
													CANCEL
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
					</Tabs>
				</Modal>
			)}
			{visible && (
				<Modal
					centered
					visible={visible}
					onOk={addMedicineSubmit}
					onCancel={addMedicineClose}
					width={1100}
				>
					<Tabs
						defaultActiveKey={1}
						className="add-medicine-main-tab"
						onChange={() => { }}
						centered
					>
						<TabPane
							key="1"
							tab={
								<span className="add-medicine-tabs">
									<p className="add-medicine-tab-title" onClick={() => { }}>
										Medicine
									</p>
								</span>
							}
						>
							<div>
								<h3 className="opd-addmedicine-title">ADD MEDICINE</h3>

								<div className="addmedicine-main-section">
									<div className="addmedicine-left-section">
										<div className="add-title-rx-search-sec">
											<div className="add-medicine-search-title">
												<span className="rx-title">Search Rx</span>
												<div className="v-line-search">
													<div className="addmedicine-search-sec">
														<div className="medicine-input">
															<input
																placeholder="Search"
																value={name}
																onChange={onMedicineNameChange}
																onBlur={handleFocusOut}
																onFocus={handleFocus}
															/>
															<CloseCircleOutlined
																className="cross-medicine"
																onClick={removeMedineName}
															/>
														</div>
														{searchMedList.length ? (
															<div
																className="custom-search-options-parent"
																style={
																	showDropDown === true
																		? {
																			visibility: "visible",
																			transition: "all 0.2s",
																		}
																		: {
																			visibility: "hidden",
																			transition: "all 0.2s",
																		}
																}
															>
																<div className="custom-search-options">
																	{searchMedList?.map((e, i) => (
																		<div
																			className="custom-search-option-item"
																			onClick={() => handleOptionSelected(i)}
																		>
																			<p>{e?.name}</p>
																			<span>
																				(
																				{e?.generic?.length &&
																					Array.isArray(e?.generic)
																					? e?.generic?.join(",")
																					: ""}
																				){" "}
																				{e?.mrp ? (
																					<span>&#8377; {e?.mrp}</span>
																				) : (
																					""
																				)}{" "}
																			</span>
																		</div>
																	))}
																</div>
															</div>
														) : (
															""
														)}
													</div>
												</div>
											</div>

											<div className="suggested-generic">
												<SwapOutlined onClick={getSearchTermMedicine} />
											</div>

											<div className="suggested-med-sec">
												<div className="search-med-list">
													{suggestedMedicines?.map(item => (
														<p
															onClick={() =>
																selectedSuggestedMedicine(item.name)
															}
														>
															{item.name}
														</p>
													))}
												</div>
											</div>
											<Divider />
											<div className="suggested-med-sec">
												<div className="selected-med-list">
													{visitInfo?.medicines?.length
														? visitInfo?.medicines?.map((item, i) => (
															<div className="selected-med-crs">
																{" "}
																<p>{item.name}</p>
																<img
																	onClick={() => deleteMedicine(i)}
																	src={crossGrey}
																	alt="alt-grey"
																/>
															</div>
														))
														: ""}
												</div>
											</div>
										</div>
									</div>
									
									<div className="v1"></div>
									
									<div className="addmedicine-right-section">
										<p className="select-med-title">Selected Medicine</p>
										<div className="med-name-select-sec">
											<Radio.Group
												onChange={onSearchRadioChange}
												value={searchType}
											>
												<Radio value="0">NAME</Radio>
												<Radio value="1">SALT</Radio>
											</Radio.Group>

											<div className="medicine-search-input">
												<InputField
													placeholder="Write Note"
													value={name}
													disabled={true}
												/>
											</div>
											<div className="add-med-place-sec">
												<SingleSelect
													data={scList}
													placeholder="Select "
													value={place}
													onChange={onPlaceChange}
												/>
											</div>
										</div>
										<div className="opd-drops-input">
											<div className="opd-drops-route">
												<SingleSelect
													data={routeList}
													placeholder="Route"
													value={dropsRoute}
													onChange={(e) => setDropsRoute(e)}
												/>
											</div>
											<div className="opd-drops-dosage">
												<InputField
													placeholder="Dosage"
													value={dropsDosage}
													onChange={(e) => setDropsDosage(e.target.value)}
												/>
											</div>
											<div className="opd-drops-dosage">
												<InputField
													placeholder="Timing"
													value={dropsTiming}
													onChange={(e) => setDropsTiming(e.target.value)}
												/>
											</div>
										</div>
										<div className="od-bd-timings-section">
											<Radio.Group
												className="med-radio-active"
												onChange={onMedicineRadioChange}
												value={freq}
											>
												<Radio value="OD">OD</Radio>
												<Radio value="BD">BD</Radio>
												<Radio value="TDS">TDS</Radio>
												<Radio value="QID">QID</Radio>
												<Radio value="SOS">SOS</Radio>
												<Radio value="STAT">STAT</Radio>
												<Radio value="Alt Day">Alt Day</Radio>
												<Radio value="Weekly">Weekly</Radio>
												<Radio value="Fortnightly">Fortnightly</Radio>
												<Radio value="Monthly">Monthly</Radio>
											</Radio.Group>
										</div>
										<div className="med-day-inc-dec-section">
											<div className="add-med-weeks">
												{medTime.altDay ||
													medTime.weekly ||
													medTime.forntNightly ||
													medTime.monthly
													? weeksData.map(item => (
														<Checkbox
															value={item.id}
															onChange={onMedWeeksChange}
														>
															{item.name}
														</Checkbox>
													))
													: ""}
											</div>
											{medTime.morning || medTime.sos || medTime.stat ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														{console.log('Inside render', medTime)}
														<Checkbox
															checked={medTime.timing.split(',').includes('Morning') || medTime.timing === 'NA'}
															value="Morning"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															MORNING
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Morning') || medTime.timing === 'NA')}
															value={medTime.qty}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qty: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.afternoon ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Afternoon') || medTime.timing === 'NA'}
															value="Afternoon"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															AFTERNOON
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Afternoon') || medTime.timing === 'NA')}
															value={medTime.qtyBD}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyBD: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.evening ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Evening') || medTime.timing === 'NA'}
															value="Evening"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															Evening
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Evening') || medTime.timing === 'NA')}
															value={medTime.qtyBD}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyBD: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											{medTime.night ? (
												<div className="med-dose-timing-sec">
													<p className="morning-check">
														<Checkbox
															checked={medTime.timing.split(',').includes('Night') || medTime.timing === 'NA'}
															value="Night"
															onChange={e => onChangeOfTimings(e.target.value)}
														>
															NIGHT
														</Checkbox>
													</p>
													<div className="med-dose-count-sec">
														<InputField
															placeholder="Qty"
															disabled={!(medTime.timing.split(',').includes('Night') || medTime.timing === 'NA')}
															value={medTime.qtyTDS}
															onChange={(e) => {
																if (isNaN(e.target.value)) return;
																setMedTime({
																	...medTime,
																	qtyTDS: e.target.value,
																});
															}}
														/>
													</div>
													<div className="med-dose-type">
														<Radio.Group
															options={medicineType}
															onChange={(e) => setSelectedMedicineType(e.target.value)}
															value={selectedMedicineType}
														/>
													</div>
												</div>
											) : (
												""
											)}
											<div className="med-dose-timing-sec">
												<p>
													<Checkbox checked={true}>DAYS</Checkbox>
												</p>
												<div className="med-days-sec">
													<SingleSelect
														data={daysList}
														value={days}
														onChange={onDaysChange}
													/>
												</div>
											</div>
											<div className="med-dose-timing-sec">
												<p className="route-continaer">
													<SingleSelect
														data={dropsCourseTypes}
														placeholder="Drops Duration"
														value={dropsCourse.type}
														onChange={(e) => setDropsCourse({
															...dropsCourse,
															type: e
														})}
													/>
												</p>
												<div className="med-weekly-course">
													<InputField
														placeholder="No of Drops"
														value={dropsCourse.noOfDrops}
														onChange={(e) => setDropsCourse({
															...dropsCourse,
															noOfDrops: e.target.value
														})}
													/>
													<Button onClick={addDropsCourseList}>ADD</Button>
												</div>
											</div>
											<div className="med-dose-timing-sec">
												<p></p>
												<div className="weekly-course-array">
													{dropsCourseArr.map((arr, index) => (
														<div className="weekly-course-array-div">
															<div>
																{`${arr.noOfDrops} Drop(s) in a day, for ${arr.type} ${index + 1}`}
															</div>
															<div>
																<div className="delete-icon-div">
																	<img
																		src={deleteIcon}
																		onClick={() => deleteDropsCourseList(index)}
																		className="medicine-table-delete"
																		alt="deleteIcon"
																	/>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>

										<div className="med-dose-desc-sec">
											<TextAreaAnt
												className={"med-note-ht"}
												placeholder="Write text here..."
												onChange={onRemarksChange}
												value={remarks}
											/>
										</div>
										<div className="warning-sec">
											<InputField
												placeholder="Warning"
												value={warning}
												onChange={onWarningChange}
											/>
										</div>
										<div className="warning-sec">
											<MultiSelect
												data={scList}
												value={
													Array.isArray(generic) && generic?.map(item => item)
												}
												dropdownClassName={"select_dropdown"}
											/>
										</div>
										<div className="opd-remarkstemplete-sec">
											{remarksTemplates?.map(item => (
												<p onClick={() => appyRemarkTemplete(item.key)}>
													{item.key}
												</p>
											))}
											<span
												className="opd-remarkstemplete-clear"
												onClick={removeRemarks}
											>
												Clear
											</span>
										</div>


										<div className="add-branch-modal-sec">
											{/* <p></p> */}
											<div className="add-branch-modal-btn">
												<Button onClick={addMedicineSubmit}>ADD</Button>
												<Button
													className={"add-branch-modal-cancel-btn"}
													onClick={addMedicineClose}
												>
													CANCEL
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
							<TabPane
							key="2"
							tab={
								<span className="add-medicine-tabs">
									<p className="add-medicine-tab-title" onClick={() => { }}>
										Glass Prescription
									</p>
								</span>
							}
						>
							<GlassPrescription closeModal={setVisible} />
						</TabPane>
					
					</Tabs>
				</Modal>
			)}
			{searchTermVisible && (
				<div className="custommodel-maincontainer">
					<div className="custommodel-centercontainer">
						<div className="custommodel-container">
							<span onClick={onCloseSearchList}>Close</span>
							{searchTermMedList?.map(e => (
								<p onClick={() => applySearchTermMed(e.name)}>
									{e.name} @ {e.mrp}
								</p>
							))}
						</div>
						<div
							className="emptyspace-custom-container"
							onClick={handleEmptySpaceClick}
						></div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Medicines;
