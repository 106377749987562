import { PauseCircleFilled, PlayCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppointmentDetails from "../../components/Opd/AppointmentDetails/AppointmentDetails";
import Audio from "../../components/Opd/Audio/Audio";
import OpdVaccine from "../../components/Opd/OpdVaccine/OpdVaccine";
import { PatientHistory } from "../../components/Opd/PatientHistory/PatientHistory";
import PatientInvoice from "../../components/Opd/PatientInvoice/PatientInvoice";
import PatientProfile from "../../components/Opd/PatientProfile/PatientProfile";
import PatientReports from "../../components/Opd/PatientReports/PatientReports";
import PatientList from "../../components/Opd/PatientsList/PatientList";
import UpdateRisks from "../../components/Opd/UpdateRisks/UpdateRisks";
import { updateVisit } from "../../redux/actions/opd/patientopdinvoice";
import {
  addMedicineOpd,
  addVisit,
  duplicateVisit,
  getSingleVisitStatus,
  openVisit,
  setHeAlertToggle,
  storeAllAppointments,
  storeAllergyFromFirebase,
  storeClinicalFindingFromFirebase,
  storeDiagnosisFromFirebase,
  storeLabtestsFromFirebase,
  storeMastersList,
  storeProcedureFromFirebase,
  storeRadiologicalFindingFirebase,
  storeVitalsFromFirebase,
} from "../../redux/actions/opd/patientreport";
import {
  loadcheckoutPatient,
  loadSendPatientDoctor,
} from "../../redux/actions/opd/sendPatientDoctor";
import { getProfileDetails } from "../../redux/actions/profile";
import Button from "../../UIComponents/Button/Button";
import CustomLoader from "../../UIComponents/CustomLoader/CustomLoader";
import Divider from "../../UIComponents/Divider/Divider";
import { toast } from "../../UIComponents/Toast";
import { db } from "../../utils/Firebase";
import "./Opd.css";
import useDoctorId from "../../utils/get-doctor-id";

const Opd = () => {
  const dispatch = useDispatch();
  // const doctorId = useSelector((state) => state.login.doctorId);
  // console.log("doctorId", doctorId);
  const doctorId = useDoctorId();
  console.log("doctorId", doctorId);

  const singleVisit = useSelector(
    (state) => state.opdrecord?.patientreport.singleVisit
  );

  const activeRedirection = useSelector(
    (state) => state.opdrecord.patientreport.active
  );

  console.log("single visit", activeRedirection);
  const show = useSelector(
    (state) => state.opdrecord?.patientreport.reportShow
  );
  console.log("showingactivetab", show);
  const visitInfo = useSelector(
    (state) => state.opdrecord?.patientopdinvoice.patientOpdInvoiceInfo
  );
  console.log("visitInfo", visitInfo);
  const saveCheckoutToggle = useSelector(
    (state) => state.opdrecord.sendPatientDoctor.saveCheckoutToggle
  );
  const alertToggle = useSelector(
    (state) => state.opdrecord.patientreport.alertToggle
  );
  const dataForNarratives = useSelector(
    (state) => state?.appointments?.getAppointment?.narrativesData
  );
  //todo
  console.log("dataForNarratives", dataForNarratives);
  const active = useSelector((state) => state.opdrecord.patientreport.active);
  console.log("single visit status", active);
  const singleVisitStatus = useSelector(
    (state) => state.opdrecord.patientreport.replicateStatus
  );
  const singleAppointment = useSelector(
    (state) => state.opdrecord.patientHistory.singleAppointment
  );
  const withDoctor = singleAppointment?.appointment?.withDoctor;
  const patientCheckedOut = singleAppointment?.appointment?.locked;

  const [audio, setAudio] = useState(false);
  const [report, setReport] = useState(true);
  const [vaccine, setVaccine] = useState(false);
  const [history, setHistory] = useState(false);

  const audioClick = () => {
    console.log("audio");
    setAudio(true);
    setReport(false);
    setVaccine(false);
    setHistory(false);
  };

  const vaccineClick = () => {
    setAudio(false);
    setReport(false);
    setVaccine(true);
    setHistory(false);
  };

  const reportClick = () => {
    setReport(true);
    setAudio(false);
    setVaccine(false);
    setHistory(false);
  };

  const historyClick = () => {
    setReport(false);
    setAudio(false);
    setVaccine(false);
    setHistory(true);
  };

  const visitSubmitHandler = () => {
    const { visitStart, variableForNarratives, ...rest } = visitInfo;
    dispatch(addVisit({ ...rest, narratives: variableForNarratives }));
  };

  const visitCloseHandler = () => {
    const { visitStart, variableForNarratives, ...rest } = visitInfo;
    if (rest?.medicines?.length > 0) {
      dispatch(
        addVisit({ ...rest, narratives: variableForNarratives }, "close visit")
      );
      dispatch(addMedicineOpd([]));
    } else {
      toast.error("Please add medicines to place order");
    }
  };

  const replicateVisitHandler = () => {
    dispatch(getSingleVisitStatus(false));
    // dispatch(openVisit({ aptId: visitInfo.aptId }))
    dispatch(duplicateVisit({ visitId: singleVisitStatus._id, days: "5" }));
  };

  const pauseHandler = () => {
    dispatch(
      loadcheckoutPatient({ aptId: visitInfo.aptId }, visitInfo.patientName)
    );
    dispatch(setHeAlertToggle(false));
  };

  const playHandler = () => {
    dispatch(
      loadSendPatientDoctor({
        aptId: visitInfo.aptId,
        web: "",
        token: visitInfo.token,
        patientName: visitInfo.patientName,
      })
    );
    dispatch(setHeAlertToggle(true));
  };
  useEffect(() => {
    dispatch(getProfileDetails({ doctorId }));
  }, []);

  useEffect(() => {
    dispatch(updateVisit({ ...visitInfo, markMedForFulfillment: true }));
  }, [visitInfo?._id]);

  useEffect(() => {
    db.ref("doctors/" + doctorId + "/appointments").off();
    db.ref("doctors/" + doctorId + "/appointments").on("value", (snapshot) => {
      console.log("snapshot002", snapshot.val());
      dispatch(storeAllAppointments(snapshot.val()));
    });
  }, [visitInfo]);

  return (
    <div className="main-section">
      <CustomLoader />
      <div style={{ display: "flex" }}>
        <div className="patient-updateRisks-div">
          {/* TODO: Rename to PatientsListing */}
          <PatientList />
          {show ? <UpdateRisks /> : ""}
        </div>
        {show ? (
          <div className="profile-container">
            <div
              style={{ display: "flex", background: "white", width: "100%" }}
              className="print-invoice-prescription"
            >
              <PatientProfile />
              <PatientInvoice />
            </div>
            {/* TODO: Convert this to a component */}
            <div className="active-visit-container">
              <div
                className={
                  active ? "disable-video pause-active-div" : "pause-active-div"
                }
              >
                <Tooltip title="Send Patient To Doctor">
                  <PlayCircleFilled className="pause" onClick={playHandler} />
                </Tooltip>
                <span className="active-visit">Active Visit</span>
              </div>
              <div className="report-btns-div">
                <Button
                  onClick={reportClick}
                  className={report ? "active" : "unactive"}
                >
                  REPORT
                </Button>
                <Button
                  onClick={historyClick}
                  className={history ? "active" : "unactive"}
                >
                  HISTORY
                </Button>
                <Button
                  onClick={audioClick}
                  className={audio ? "active" : "unactive"}
                >
                  AUDIO
                </Button>
                <Button
                  onClick={() => vaccineClick()}
                  className={vaccine ? "active" : "unactive"}
                >
                  VACCINE
                </Button>
              </div>
              <div className="">
                <Divider />
                <div className="appointment-details">
                  <AppointmentDetails />
                  {singleVisitStatus.active ? (
                    <div className="save-checkout">
                      <Button
                        className={"opd-save-btn"}
                        onClick={visitSubmitHandler}
                      >
                        SAVE VISIT
                      </Button>
                      <Button
                        className={"checkout-btn"}
                        onClick={visitCloseHandler}
                      >
                        CLOSE VISIT
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        className={"opd-save-btn"}
                        onClick={replicateVisitHandler}
                      >
                        REPLICATE VISIT
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="patient-reports-opd-div">
              {report ? <PatientReports /> : ""}
            </div>
            <div>{history ? <PatientHistory /> : ""}</div>
            <div className="patient-reports-opd-div">
              {audio ? <Audio /> : ""}
            </div>
            <div className="patient-reports-opd-div">
              {vaccine ? <OpdVaccine /> : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Opd;
